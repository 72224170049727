import React, { useCallback, useEffect, useContext, useState } from 'react';
import notify from 'notify';
import {
    getDocumentsSignedByUserList
} from './api';
import styled from '@emotion/styled';
import MainLayout from 'components/MainLayout';
import { WorkspaceContext } from 'workspace';
import { info } from 'sitedata';

const SignedDocumentsPage = () => {
    const { openWorkspace } = useContext(WorkspaceContext);

    const [documents, setDocs] = useState<any>(null);

    const fetchDocs = useCallback(async () => {
        try {
            const docs = await getDocumentsSignedByUserList();

           // console.log(signs);
           setDocs(docs);
        } catch (err) {
            notify(err.message);
        }
    }, [getDocumentsSignedByUserList]);

    useEffect(() => {
        fetchDocs();
    }, [fetchDocs]);
    
    if (!documents || documents.length === 0) return (
        <MainLayout><StyledDashboardPage className="documents-page"><p className="documents-page__empty">No signed documents.</p></StyledDashboardPage></MainLayout>
    );
    //console.log(documents);
    return (
        <MainLayout>
            <StyledDashboardPage className="documents-page">
                <div className="signatures__header">
                <h4 className="signatures__title">Your signed documents</h4>
                
                </div>
            
                <div className="documents-container">
                    
                    <table className="signatures-list">
                        <thead>
                            <tr>
                            <th>Property Address</th>
                            <th>Document</th>
                            
                            <th>Signing Finished?</th>
                            <th>Certificate</th>
                            <th>Role</th>
                            <th>Date Signed</th>
                   
                            {/*  eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                            <th />
                            </tr>
                        </thead>
                        <tbody>
                            {documents.map(node => (
                            <tr>
                                {node.listing_id && <td onClick={() => openWorkspace(node.listing_id)} className='listedPropAddress'>{node.propaddress}</td>}
                                {!node.listing_id && <td>-</td>}
                                <td><a target="_blank" href={node.link || undefined}>{node.filename}</a></td>
          
                                <td>{node.signing_finished == 1 ? "Yes" : "No"}</td>
                                {node.signing_finished == 1 && <td><a target="_blank" href={info.apiUrl+(info.apiUrl.slice(-1) !== '/' ? "/":"")+"documents/certificates/"+node.id+"/"+node.listing_id+"/"+node.created_on}>OPEN</a></td>}
                                {node.signing_finished != 1 && <td>Not Signed</td>}
                                <td>{node.role_specific == null ? "No Role Defined" : node.role_specific.match(/[A-Z][a-z]+/g).join(" ")}</td>
                                <td>{node.signed_on}</td>
                               
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
        </StyledDashboardPage>
      </MainLayout>
    )

}

export default SignedDocumentsPage;


const StyledDashboardPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  a{color: black}

  .listedPropAddress{cursor:pointer;}

  .page_explanation{    text-align: left;
    width: 100%;
    margin: 0;}

  .ttNotSigned{color: #df1721;cursor:pointer;}

  .signatures__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
    margin: 16px 0;

    h4 {
      margin: 0;
    }
  }

  .documents-page {
    &__header {
      display: flex;
      justify-content: space-between;
      margin: 0 0 28px;
      width: 100%;
    }
    &__empty {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      line-height: 24px;
      color: ${props => props.theme.colors.grayDark};
    }
    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: black;
      margin: 0;
      outline: none;

      &.breadcrumb {
        cursor: pointer;
        &:hover,
        &:active,
        &:focus {
          opacity: 0.7;
        }
      }
    }

    &__breadcrumbs {
      display: flex;
      height: 32px;
      .breadcrumb {
        display: flex;
        font-weight: normal;
        font-size: 16px;
        line-height: 32px;
        white-space: nowrap;
        align-items: center;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: none;
        &:hover,
        &:active {
          opacity: 0.7;
        }
        .icon {
          flex-shrink: 0;
          transform: rotate(270deg);
          width: 24px;
          height: 24px;
        }
      }
    }

    &__mobile-folder {
      display: flex;
      align-items: center;

      h5 {
        margin: 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        white-space: nowrap;
      }

      .button {
        background: ${props => props.theme.colors.seashell};
        margin-right: 12px;

        .icon {
          vertical-align: middle;
          fill: black;
        }
      }
    }
    &__controls {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;

      & > .button,
      & > .input {
        margin-left: 16px;
        height: 32px;
      }

      .input-component {
        height: 32px !important;
      }

      .button .icon {
        vertical-align: middle;
      }
    }

    &__search {
      width: 100%;
      max-width: 300px;
    }

    &__upload_btn {
      width: 90px;
    }
    &__empty {
      padding: 20px;
      text-align: center;
    }
  }

  .search {
    .input-wrapper {
      .input-component {
        height: 32px;
      }
    }
  }

  .documents-container {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 300px;
    margin-top: 32px;
  }

  .signatures-list {
    width: 100%;
    thead th {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      text-align: left;
      color: ${props => props.theme.colors.grayDark};
      padding-bottom: 9px;
      border-bottom: 1px solid ${props => props.theme.colors.seashell};
    }
    &:not(:last-of-type) td {
        border-bottom: 1px solid ${props => props.theme.colors.seashell};
    }
    td {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        height: 32px;
    }
  }

  .signatures-list-mobile {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    .documents-page {
      &__header {
        margin-bottom: 0;
      }

      &__controls {
        .button {
          width: auto;
          margin-left: 8px !important;
        }
      }
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .documents-page {
      &__folder_btn {
        margin-left: 8px;
      }
    }
  }
`;