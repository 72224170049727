import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from 'components/PrivateRoute';
import SignedDocumentsPage from 'views/SignedDocumentsPage';
import Workspace from 'workspace';

const SignedDocuments = () => {
  return (
    <Workspace>
      <Router>
        <PrivateRoute path="/signed-documents" component={SignedDocumentsPage} />
      </Router>
    </Workspace>
  );
};

export default SignedDocuments; 
